<template>
    <div class="idxBox">
        <el-scrollbar class="hid grow" style="height: 100%;" v-if="detailInfo">
            <div class="carouselBox" v-if="detailInfo.picture">
                <el-carousel height="4.8rem" indicator-position="none" autoplay @change="change">
                    <el-carousel-item
                        v-for="item in detailInfo.picture.split(',')"
                        :key="item">
                        <img :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>
                <div class="imgNum">
                    <!-- detailInfo.picture.split(',') -->
                    {{ imageIndex }}/{{ detailInfo.picture.split(',').length }}
                </div>
            </div>

            <div class="actionTitle">
                {{ detailInfo.title || '--' }}
            </div>
            <div class="actionTime">
                {{ detailInfo.releaseTime || '--' }}
            </div>

            <div class="countyContent" v-html="detailInfo.content">
            </div>
        </el-scrollbar>
    </div>
</template>
    
<script>
import {
    getNewDetail
} from "@/api";
import { } from "@/components";
export default {
    components: {

    },
    data() {
        return {
            detailInfo: false,
            imageIndex: 1,
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

        let id = this.$route.query.id;
        getNewDetail({ id }).then(res => {
            this.detailInfo = res.data
        })
    },
    methods: {
        change(e) {
            this.imageIndex = e + 1
        }
    },
};
</script>
<style lang="scss" scoped>
.carouselBox {
    width: 100%;
    height: 4.8rem;
    background: #FFFFFF;
    border-radius: 0.1rem;
    position: relative;

    .imgNum {
        width: 1.5rem;
        height: 0.6rem;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0.3rem;
        position: absolute;
        left: 50%;
        bottom: 0.2rem;
        transform: translate(-50%, 0%);
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.36rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
    }

}

.actionTitle {
    font-size: 0.5rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #6F4F2A;
    line-height: 0.7rem;
    margin-top: 0.6rem;
}

.actionTime {
    font-size: 0.4rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #906E45;
    opacity: 0.6;
    margin: 0.32rem 0 0.7rem 0;
}

.countyContent {
    font-size: 0.36rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #906E45;
    line-height: 0.72rem;
    padding-right: 0.32rem;
    box-sizing: border-box;
}
</style>
    
    